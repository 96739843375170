import {
  FrequentlyAskedQuestionCategoriesHeader,
  FrequentlyAskedQuestionCategoriesAccordion,
} from "../../Components/FrequentlyAskedQuestionCategories/FrequentlyAskedQuestionCategories";

const FrequentlyAskedQuestionCategories = () => {
  return (
    <div className="content-wrapper">
      <div className="content">
        <FrequentlyAskedQuestionCategoriesHeader />
        <FrequentlyAskedQuestionCategoriesAccordion />
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestionCategories;
