import { memo, ReactNode } from "react";
import { useGetUserFrequentlyAskedQuestionCategories } from "../../../features/frequentlyAskedQuestionCategories/api";
import LoadingSpinner from "../../common/LoadingSpinner";
import NoDataFound from "../../common/NoDatFound";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FrequentlyAskedQuestionsAccordion from "../../FrequentlyAskedQuestions/FrequentlyAskedQuestionsAccordion";

export const FrequentlyAskedQuestionCategoriesAccordion = () => {
  const { data, isLoading, isError } =
    useGetUserFrequentlyAskedQuestionCategories({ pageIndex: 0, pageSize: 0 });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || data.frequentlyAskedQuestionCategories.length === 0) {
    return <NoDataFound />;
  }

  return (
    <FrequentlyAskedQuestionCategoriesListWrapper>
      {data.frequentlyAskedQuestionCategories.map(({ id, name }) => (
        <Accordion key={id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">{name}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <FrequentlyAskedQuestionsAccordion
              frequentlyAskedQuestionCategoryId={id}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </FrequentlyAskedQuestionCategoriesListWrapper>
  );
};

const FrequentlyAskedQuestionCategoriesListWrapper = memo(
  function FrequentlyAskedQuestionCategoriesListWrapper({
    children,
  }: {
    children?: ReactNode;
  }) {
    return (
      <section className="page-content container-fluid">{children}</section>
    );
  },
);

export default FrequentlyAskedQuestionCategoriesAccordion;
