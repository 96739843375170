import { Box, Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Grid from "@mui/material/Unstable_Grid2";
import { FormattedMessage } from "react-intl";
import { PricingTier } from "../../Components/VpsMachine/ChocopingVpsDiscountCodes";
import ChocopingVpsDiscountCodeCard from "../../Components/VpsMachine/ChocopingVpsDiscountCodes/ChocopingVpsDiscountCodeCard";

interface PricingSection {
  title: string;
  type: string;
  tiers: PricingTier[];
}

export default function ChocopingVpsDiscountCodes() {
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-right">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="menu.vpsMachine.chocopingVpsDiscountCodes"
                  defaultMessage="Chocoping VPS Discount Codes"
                />
              </h1>
            </div>
          </div>
        </header>

        <div className="pricing-section-wrapper">
          <div className="chocoping-vps-discount-codes-page-description">
            <FormattedMessage
              id="chocopingVpsDiscountCodesPage.description"
              defaultMessage="Enter the exclusive VPS offer website for Algo Forest, the corporate brand of Forex Forest: {promotionUrl}{br}Enjoy different discounts based on your needs. We offer discounts of up to 20%!{br}Click the button below to download the manual and follow the guide for a seamless experience!"
              values={{
                promotionUrl: (
                  <a
                    href="https://chocoping.com/algoforest"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://chocoping.com/algoforest
                  </a>
                ),
                br: <br />,
              }}
            />
          </div>
          <Button
            href="https://s3.ap-east-1.amazonaws.com/gemsfx.com/Algoforest+chocoping+vps+note.pdf"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<CloudDownloadIcon />}
            variant="contained"
            style={{ color: "white" }}
          >
            <FormattedMessage
              id="chocopingVpsDiscountCodesPage.downloadInstruction"
              defaultMessage="Download Instruction"
            />
          </Button>
        </div>

        <div className="pricing-section-wrapper">
          <div className="chocoping-vps-discount-codes-page-banner">
            Forex Forest ⨉ Chocoping VPS Discount
          </div>
        </div>

        {pricingSections.map((section, i) => {
          const { title, type, tiers } = section;

          return (
            <div key={i} className="pricing-section-wrapper">
              <div className="pricing-section">
                <div className="pricing-header">
                  <h2 className="pricing-type">{type}</h2>
                  <h3 className="pricing-title">{title}</h3>
                </div>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 2, sm: 8, md: 12 }}
                  >
                    {tiers.map((tier, j) => (
                      <Grid xs={2} sm={4} md={3} key={`${i}#${j}`}>
                        <ChocopingVpsDiscountCodeCard pricingTier={tier} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const pricingSections: PricingSection[] = [
  {
    title: "CHOCO - 2GB",
    type: "VPS",
    tiers: [
      {
        duration: "1 MONTHS",
        originalPrice: 16.99,
        discountedPrice: 16.14,
        discount: 5,
        saved: 0.85,
        promoCode: "ALGOFOREST-OBTS4",
      },
      {
        duration: "3 MONTHS",
        originalPrice: 50.97,
        discountedPrice: 46.89,
        discount: 8,
        saved: 4.08,
        promoCode: "ALGOFOREST-ZFADH",
      },
      {
        duration: "6 MONTHS",
        originalPrice: 101.94,
        discountedPrice: 89.71,
        discount: 12,
        saved: 12.23,
        promoCode: "ALGOFOREST-QVT2F",
      },
      {
        duration: "1 YEAR",
        originalPrice: 203.88,
        discountedPrice: 167.18,
        discount: 18,
        saved: 36.7,
        promoCode: "ALGOFOREST-P4LH9",
        isPopular: true,
      },
    ],
  },
  {
    title: "CHOCO - 4GB",
    type: "VPS",
    tiers: [
      {
        duration: "1 MONTH",
        originalPrice: 35.99,
        discountedPrice: 32.39,
        discount: 10,
        saved: 3.6,
        promoCode: "ALGOFOREST-5NO9H",
      },
      {
        duration: "3 MONTHS",
        originalPrice: 107.97,
        discountedPrice: 91.77,
        discount: 15,
        saved: 16.2,
        promoCode: "ALGOFOREST-RF5EW",
      },
      {
        duration: "6 MONTHS",
        originalPrice: 215.94,
        discountedPrice: 177.07,
        discount: 18,
        saved: 38.87,
        promoCode: "ALGOFOREST-ABO4C",
      },
      {
        duration: "1 YEAR",
        originalPrice: 431.88,
        discountedPrice: 345.5,
        discount: 20,
        saved: 86.38,
        promoCode: "ALGOFOREST-3BVMU",
        isPopular: true,
      },
    ],
  },
];
