import { AxiosError } from "axios";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useEffect } from "react";
import { customFetch } from "../../../util/axios";
import { FrequentlyAskedQuestionCategoryResponse } from "../types";
import { GetUserFrequentlyAskedQuestionCategoriesQueryDto } from "../schemas";

export interface GetUserFrequentlyAskedQuestionCategoriesResponse {
  frequentlyAskedQuestionCategoriesCount: number;
  frequentlyAskedQuestionCategoriesTotalPages: number;
  frequentlyAskedQuestionCategoriesHasNextPage: boolean;
  frequentlyAskedQuestionCategories: FrequentlyAskedQuestionCategoryResponse[];
}

export const getUserFrequentlyAskedQuestionCategories = async (
  getUserFrequentlyAskedQuestionCategoriesQuery: GetUserFrequentlyAskedQuestionCategoriesQueryDto,
) => {
  const response = await customFetch.get(
    "/users/frequently-asked-question-categories",
    {
      params: getUserFrequentlyAskedQuestionCategoriesQuery,
    },
  );
  return response.data;
};

export const useGetUserFrequentlyAskedQuestionCategories = (
  getUserFrequentlyAskedQuestionCategoriesQuery: GetUserFrequentlyAskedQuestionCategoriesQueryDto,
  options?: UseQueryOptions<
    GetUserFrequentlyAskedQuestionCategoriesResponse,
    AxiosError | Error
  >,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<
    GetUserFrequentlyAskedQuestionCategoriesResponse,
    AxiosError | Error
  >({
    queryKey: [
      "users",
      "frequently-asked-question-categories",
      getUserFrequentlyAskedQuestionCategoriesQuery,
    ],
    queryFn: () =>
      getUserFrequentlyAskedQuestionCategories(
        getUserFrequentlyAskedQuestionCategoriesQuery,
      ),
    keepPreviousData: true,
    ...options,
  });

  const pageIndex =
    getUserFrequentlyAskedQuestionCategoriesQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.frequentlyAskedQuestionCategoriesHasNextPage) {
      const nextParams = {
        ...getUserFrequentlyAskedQuestionCategoriesQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: ["users", "frequently-asked-question-categories", nextParams],
        queryFn: () => getUserFrequentlyAskedQuestionCategories(nextParams),
      });
    }
  }, [
    getUserFrequentlyAskedQuestionCategoriesQuery,
    data,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
