import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { useGetUserFrequentlyAskedQuestionsByCategoryId } from "../../features/frequentlyAskedQuestionCategories/api";
import LoadingSpinner from "../common/LoadingSpinner";
import NoDataFound from "../common/NoDatFound";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiTiptapEditorReadOnly from "../MuiTiptapEditor/MuiTiptapEditorReadOnly";

interface FrequentlyAskedQuestionsAccordionProps {
  frequentlyAskedQuestionCategoryId: number;
}

const FrequentlyAskedQuestionsAccordion = (
  props: FrequentlyAskedQuestionsAccordionProps,
) => {
  const { frequentlyAskedQuestionCategoryId } = props;
  const { data, isLoading, isError } =
    useGetUserFrequentlyAskedQuestionsByCategoryId({
      params: { frequentlyAskedQuestionCategoryId },
      query: { pageIndex: 0, pageSize: 0 },
    });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !data.frequentlyAskedQuestions.length) {
    return <NoDataFound />;
  }

  return (
    <>
      {data.frequentlyAskedQuestions.map((frequentlyAskedQuestion) => (
        <Accordion key={frequentlyAskedQuestion.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ fontWeight: "bold" }}>
              {frequentlyAskedQuestion.question}
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <MuiTiptapEditorReadOnly content={frequentlyAskedQuestion.answer} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FrequentlyAskedQuestionsAccordion;
