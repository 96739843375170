import { RichTextEditorProps, RichTextReadOnly } from "mui-tiptap";
import useExtensions from "./hooks/useExtensions";

interface MuiTiptapEditorReadOnlyProps {
  content: RichTextEditorProps["content"];
}

const MuiTiptapEditorReadOnly = (props: MuiTiptapEditorReadOnlyProps) => {
  const { content } = props;
  const extensions = useExtensions({ placeholder: "Add your answer here..." });

  return <RichTextReadOnly content={content} extensions={extensions} />;
};

export default MuiTiptapEditorReadOnly;
