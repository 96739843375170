import { AxiosError } from "axios";
import { useEffect } from "react";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { customFetch } from "../../../util/axios";
import { FrequentlyAskedQuestionResponse } from "../../frequentlyAskedQuestions/types";
import {
  GetUserFrequentlyAskedQuestionsByCategoryIdParamsDto,
  GetUserFrequentlyAskedQuestionsByCategoryIdQueryDto,
} from "../schemas";

export interface GetUserFrequentlyAskedQuestionsByCategoryIdResponse {
  frequentlyAskedQuestionsCount: number;
  frequentlyAskedQuestionsTotalPages: number;
  frequentlyAskedQuestionsHasNextPage: boolean;
  frequentlyAskedQuestions: FrequentlyAskedQuestionResponse[];
}

interface GetUserFrequentlyAskedQuestionsByCategoryIdData {
  params: GetUserFrequentlyAskedQuestionsByCategoryIdParamsDto;
  query: GetUserFrequentlyAskedQuestionsByCategoryIdQueryDto;
}

export const getUserFrequentlyAskedQuestionsByCategoryId = async (
  frequentlyAskedQuestionCategoryId: number,
  query: GetUserFrequentlyAskedQuestionsByCategoryIdQueryDto,
) => {
  const response = await customFetch.get(
    `/users/frequently-asked-question-categories/${frequentlyAskedQuestionCategoryId}/frequently-asked-questions`,
    { params: query },
  );
  return response.data;
};

export const useGetUserFrequentlyAskedQuestionsByCategoryId = (
  getUserFrequentlyAskedQuestionsByCategoryIdData: GetUserFrequentlyAskedQuestionsByCategoryIdData,
  options?: UseQueryOptions<
    GetUserFrequentlyAskedQuestionsByCategoryIdResponse,
    AxiosError | Error
  >,
) => {
  const queryClient = useQueryClient();

  const {
    params: { frequentlyAskedQuestionCategoryId },
    query: getUserFrequentlyAskedQuestionsByCategoryIdQuery,
  } = getUserFrequentlyAskedQuestionsByCategoryIdData;

  const query = useQuery<
    GetUserFrequentlyAskedQuestionsByCategoryIdResponse,
    AxiosError | Error
  >({
    queryKey: [
      "users",
      "frequently-asked-question-categories",
      frequentlyAskedQuestionCategoryId,
      "frequently-asked-questions",
      getUserFrequentlyAskedQuestionsByCategoryIdQuery,
    ],
    queryFn: () =>
      getUserFrequentlyAskedQuestionsByCategoryId(
        frequentlyAskedQuestionCategoryId,
        getUserFrequentlyAskedQuestionsByCategoryIdQuery,
      ),
    keepPreviousData: true,
    ...options,
  });

  const pageIndex =
    getUserFrequentlyAskedQuestionsByCategoryIdQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.frequentlyAskedQuestionsHasNextPage) {
      const nextParams = {
        ...getUserFrequentlyAskedQuestionsByCategoryIdQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: [
          "users",
          "frequently-asked-question-categories",
          frequentlyAskedQuestionCategoryId,
          "frequently-asked-questions",
          nextParams,
        ],
        queryFn: () =>
          getUserFrequentlyAskedQuestionsByCategoryId(
            frequentlyAskedQuestionCategoryId,
            nextParams,
          ),
      });
    }
  }, [
    data,
    getUserFrequentlyAskedQuestionsByCategoryIdQuery,
    frequentlyAskedQuestionCategoryId,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
