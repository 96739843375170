import { useState } from "react";

export interface PricingTier {
  duration: string;
  originalPrice: number;
  discountedPrice: number;
  discount: number;
  saved: number;
  promoCode: string;
  isPopular?: boolean;
}

interface ChocopingVpsDiscountCodeCardProps {
  pricingTier: PricingTier;
}

export default function ChocopingVpsDiscountCodeCard(
  props: ChocopingVpsDiscountCodeCardProps,
) {
  const {
    duration,
    originalPrice,
    discountedPrice,
    discount,
    saved,
    promoCode,
    isPopular = false,
  } = props.pricingTier;

  const [copiedCode, setCopiedCode] = useState<string | null>(null);

  const copyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code);
      setTimeout(() => setCopiedCode(null), 2000);
    });
  };

  return (
    <div
      key={duration}
      className={`pricing-card ${isPopular ? "popular" : ""}`}
    >
      {isPopular && <div className="popular-banner">MOST POPULAR</div>}
      <div className="pricing-content">
        <div className="pricing-duration">{duration}</div>

        <div className="pricing-details">
          <div className="original-price">USD {originalPrice.toFixed(2)}</div>
          <div className="discounted-price">
            USD {discountedPrice.toFixed(2)}
          </div>
          <div className="discount-badge">{discount}% OFF</div>
          <div className="saved-amount">Saved ${saved.toFixed(2)}</div>
        </div>

        <div className="promo-footer">
          <div className="promo-label">PROMO CODE</div>
          <div className="promo-code-container">
            <div className="promo-code" title={promoCode}>
              {promoCode}
            </div>
            <button
              className="copy-button"
              onClick={() => copyToClipboard(promoCode)}
            >
              {copiedCode === promoCode ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
